/*

Module de recherche dans les métiers ROME

*/
import {useState} from 'react'
import axios from 'axios';
import TextField  from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Rome({api}){

    const [state, setState] = useState({metier:'', rows:[]})

    // const host = 'http://localhost:2000'

    const seek = () => {
        axios.get(`${api}/rome?metier=${state.metier}`)
        .then(function (response) {
            // handle success
            console.log(response.data);
            setState({...state, rows:response.data})
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
            console.log("the end")
          });

    }

    const handelChange = (e) => {
        setState({...state, metier:e.target.value})
    }

    const kp = (event) => {
      if(event.key === 'Enter'){seek()}    
    }

    return(
        <>
        <h1>Module de recherche ROME</h1>
        {/* <TextField
                style = {{width: 300}}
                id="metier"
                name=''
                label="Métier recherché"
                //value={state.cet.iccpRate}
                 onChange={(e)=>handelChange()}
                //onQ={handelChange}
                InputLabelProps={{ shrink: true }} 
                size="small"
            />      */}

        <InputLabel htmlFor="metier">
          Métier recherché
        </InputLabel>
        <Input
        id="metier"
        onChange={handelChange}
        onKeyPress={kp}
        endAdornment={
            <InputAdornment position="end">
                <SearchIcon 
                onClick={seek}
                />
            </InputAdornment>
            }
        >
        </Input>

        {/* https%3A%2F%2Fcandidat.pole-emploi.fr%2Fmarche-du-travail%2Ffichemetierrome.blocficherome.telechargerpdf%3FcodeRome%3DF1301&usg=AOvVaw3_wEAb3YuY_hcqGntqdzQu */}

        <TableContainer component={Paper} >
      <Table sx={{ maxWidth: 650, margin:'auto' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Métier</TableCell>
            <TableCell align="right">Code</TableCell>
            {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.lib}
              </TableCell>
              <TableCell align="right">{row.id}</TableCell>
              {/* <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>




        </>
        )

}