import logo from './logo.svg';
// import './App.css';
import './profil.css';

import Rome from './components/Rome'
import axios from 'axios';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <Rome api='https://profils.ovh/api' />
      </header>
    </div>
  );
}

export default App;
